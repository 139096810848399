import axios from 'axios';

import { Conversation } from '@/models/ai/conversation.model';
import { DocumentSource } from '@/models/ai/document.source.model';
import { BaseAnalysis, AnalysisCheck, PaginatedAnalysis, Feedback } from '@/models/ai/document.analysis.model';

class JuraNewsAiService {

  // AI
  async getUserConversations(jwtToken: string): Promise<Conversation[]> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/conversation';
    const conversations: Conversation[] = [];
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: jwtToken,
      },
    });
    for (const conv of result.data) {
      const conversation = new Conversation(
                conv.username,
                conv.conversation_id
              );
      conversation.title = conv.title;
      conversation.conversationDate = conv.conversation_date;
      conversations.push(conversation);
    }
    return conversations;
  }

  async getUserConversation(id: string, jwtToken: string): Promise<Conversation> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/conversation/' + id;
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: jwtToken,
      },
    });
    return result.data as Conversation;
  }

  async deleteUserConversation(id: string, jwtToken: string): Promise<Conversation> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/conversation/' + id;
    const result = await axios({
      method: 'delete',
      url: url,
      headers: {
        apikey: jwtToken,
      },
    });
    return result.data as Conversation;
  }

  async sendAnalysisFeedback(analysisId: number, rating: number, ratingComment: string, jwtToken: string): Promise<Conversation> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/analysis/feedback';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: jwtToken,
      },
      data: {
        id: analysisId,
        rating: rating,
        rating_comment: ratingComment
      }
    });
    return result.data as Conversation;
  }

  async sendGenerationFeedback(generationId: string, rating: number, ratingComment: string, jwtToken: string): Promise<Conversation> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/generation/feedback';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: jwtToken,
      },
      data: {
        id: generationId,
        rating: rating,
        rating_comment: ratingComment
      }
    });
    return result.data as Conversation;
  }

  async documentAnalysis(llmModelName: string, promptTemplate: string, documentSource: DocumentSource, jwtToken: string): Promise<BaseAnalysis> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/analysis';
    console.log({
      llm_model_name: llmModelName,
      prompt_template: promptTemplate,
      document: documentSource
    })
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: jwtToken,
      },
      data: {
        llm_model_name: llmModelName,
        prompt_template: promptTemplate,
        document: documentSource
      }
    });

    return result.data.document_analysis as BaseAnalysis;
  }

  async analysisCheck(llmModelName: string, promptTemplate: string, documentSource: DocumentSource, jwtToken: string): Promise<AnalysisCheck> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/analysis/check';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: jwtToken,
      },
      data: {
        llm_model_name: llmModelName,
        prompt_template: promptTemplate,
        document: documentSource
      }
    });
    return result.data as AnalysisCheck;
  }

  async getPaginatedAnalysis(
                      page: number,
                      size: number,
                      verified: boolean | undefined = undefined,
                      categoryIds: number[],
                      idJuraNews: number | undefined,
                      idJuranet: number | undefined,
                      promptTemplates: string[],
                      jwtToken: string): Promise<PaginatedAnalysis> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/analysis/paged';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: jwtToken,
      },
      data: {
        page: page,
        size: size,
        verified: verified,
        category_ids: categoryIds,
        juranews_id: idJuraNews,
        juranet_id: idJuranet,
        prompt_templates: promptTemplates
      }
    });
    return result.data as PaginatedAnalysis;
  }

  async getFeedbacks(
    id: number,
    jwtToken: string): Promise<Feedback[]> {
    const url = process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/analysis/feedback/' + id;
    const result = await axios({
        method: 'get',
        url: url,
        headers: {
        apikey: jwtToken,
      },
    });
    return result.data as Feedback[];
  }

  async updateDocumentAnalysis(documentAnalysis: BaseAnalysis, jwtToken: string): Promise<BaseAnalysis> {
    const url =
      process.env.VUE_APP_JURANEWS_AI_GENERATOR_SERVICES_URL + '/analysis';
    const result = await axios({
      method: 'patch',
      url: url,
      headers: {
        apikey: jwtToken,
      },
      data: documentAnalysis
    });
    return result.data as BaseAnalysis;
  }

}

export const JnAiService = new JuraNewsAiService();
