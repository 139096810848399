export class Conversation {

  username: string;
  conversationId: string;  
  selected = false;
  title: string | undefined;
  conversationDate: Date | undefined;
  history: History[];
  rating: number | undefined;
  ratingComment: string | undefined;

  constructor(
              username: string,
              conversationId: string) {
    this.username = username;
    this.conversationId = conversationId;   
    this.history = []; 
  }

}
