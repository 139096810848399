
import { Component, Vue, Prop } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import htmlToDocx from 'html-to-docx-typescript';
import { saveAs } from "file-saver";
import EventBus from "@/services/event.bus";
import { EsService } from "@/services/es.service";
import { SearchData } from "@/models/search.model";

@Component({
  components: {},
})
export default class JnWord extends Vue {
  @Prop({ required: false })
  doc?: Document;

  @Prop({ required: false })
  analysisHtml?: string;

  loading = true;

  removeImgTags(html: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const imgTags = doc.querySelectorAll('img');
    imgTags.forEach(img => img.remove());
    return doc.documentElement.outerHTML;
  }

  removeBackground(htmlString: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const elements = doc.querySelectorAll('[style]');

    elements.forEach(element => {
        const style = element.getAttribute('style');
        if (style) {
            const styleMap = style.split(';').reduce((map, styleProp) => {
                const [property, value] = styleProp.split(':').map(str => str.trim());
                if (property && value) {
                    map[property] = value;
                }
                return map;
            }, {} as { [key: string]: string });


            delete styleMap['background'];
            delete styleMap['background-color'];
            delete styleMap['background-image'];

            const newStyle = Object.entries(styleMap).map(([prop, val]) => `${prop}: ${val}`).join('; ');
            if (newStyle) {
                element.setAttribute('style', newStyle);
            } else {
                element.removeAttribute('style');
            }
        }
    });
    return doc.body.innerHTML;
}

  async convertToWord() {
    EventBus.$emit("word-create-start");
    let docToConvert = undefined;
    const jwtToken = String(this.$cookies.get("jwtToken"));
    if (this.doc && this.doc.text) {
      docToConvert = this.doc;
    } else {
      const docSearchData = new SearchData({
        index: process.env.VUE_APP_JURANET_INDEXES!,
        id: this.doc?.id,
        size: 1,
        from: 0,
        withText: true,
      });
      docToConvert = await EsService.getDoc(docSearchData, jwtToken);
    }
    
    //const analysisPreparedForPrint = this.removeBackground(this.removeImgTags(this.analysisHtml!));
    const analysisPreparedForPrint = this.removeImgTags(this.analysisHtml!);
    const finalHtml = docToConvert?.getTextWithDisclaimer(analysisPreparedForPrint)!
    console.log(finalHtml)
    const blob = await htmlToDocx(finalHtml, "", {}, "")

    const docName = docToConvert!.categories[0] + "-" + docToConvert!.id;
    saveAs(blob as Blob, docName);
    EventBus.$emit("word-create-stop");
  }
}
