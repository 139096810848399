import { StructureElement } from './structure.element.model';

interface StructureProps {
  // Deve essere number per il TreeView
  idTree?: number;
  id: string;
  docId: string;
  name: string;
  idCategory: string;
  text?: string;
  jsonStructure: any;
}

export class Structure {
  private props: StructureProps;

  private children: StructureElement[] = [];

  // Contatore id dei figli
  private childId = 2;
  // Mappa id ("docId #target#type") all'id numerico del tree
  private idMap = new Map();

  constructor(props: StructureProps) {
    this.props = props;
    this.idTree = 1;
    this.build();
  }

  keyByElement(element: StructureElement) {
    if (element.version) {
      return (
        element.docId +
        '#' +
        element.target +
        '#' +
        element.type +
        '#' +
        element.version
      );
    }
    return element.docId + '#' + element.target + '#' + element.type;
  }

  key(
    docId: string,
    target: string,
    type: string,
    version: string | undefined
  ) {
    if (version) {
      return docId + '#' + target + '#' + type + '#' + version;
    }
    return docId + '#' + target + '#' + type;
  }

  getChild(id: string): StructureElement | undefined {
    return this.getChildImpl(this.children, id);
  }

  private getChildImpl(
    children: StructureElement[],
    id: string
  ): StructureElement | undefined {
    let child: StructureElement | undefined;
    for (const c of children) {
      if (c.id === id) {
        child = c;
      }
      if (!child) {
        child = this.getChildImpl(c.getChildren(), id);
      }
    }
    return child;
  }

  getChildrenByArticleNumber(numArticle: string) {
    return this.getChildrenByArticleNumberImpl(this.children, numArticle, [] as StructureElement[]);
  }

  private getChildrenByArticleNumberImpl(
    children: StructureElement[],
    numArticle: string,
    foundChildren: StructureElement[],
  ): StructureElement[] {
    let child: StructureElement | undefined;
    for (const c of children) {
      const splitted = c.id.split("#");
      console.log(splitted)
      if (splitted[1].split("_")[0] === numArticle && splitted[2] === 'articolo') {
        foundChildren.push(c);
      }
      if (!child) {
        foundChildren = this.getChildrenByArticleNumberImpl(c.getChildren(), numArticle, foundChildren);
      }
    }
    return foundChildren;
  }


  getChildren(): StructureElement[] {
    return this.children;
  }

  private build() {
    for (const tag of this.props.jsonStructure.tags) {
      const element = new StructureElement({
        idTree: this.childId++,
        id: this.key(this.props.docId, tag.target, tag.type, undefined),
        docId: this.props.docId,
        target: String(tag.target),
        type: String(tag.type),
        name: String(tag.label),
        idCategory: String(this.props.idCategory),
        since: String(tag.data_decorrenza),
        historySize: Number(tag.historySize),
        breadcrumb: tag.label,
      });
      this.idMap.set(element.id, element.id);

      if (element.historySize > 0) {
        const historyElements: StructureElement[] = [];
        for (let i = 0; i < element.historySize; i++) {
          historyElements.push(
            new StructureElement({
              idTree: this.childId++,
              id: this.key(
                this.props.docId,
                tag.target,
                tag.type,
                String(i + 1)
              ),
              docId: this.props.docId,
              target: String(tag.target),
              type: String(tag.type),
              name: 'Versione ' + String(i + 1),
              version: String(i + 1),
              idCategory: String(this.props.idCategory),
              since: String(tag.data_decorrenza),
              historySize: Number(tag.historySize),
            })
          );
        }
        element.addChildren(historyElements);
      } else if (tag.tags) {
        element.addChildren(this.buildChildren(tag.tags, tag.label));
      }
      this.children.push(element);
    }
  }

  private buildChildren(tags: any, parentName: string): StructureElement[] {
    const children: StructureElement[] = [];
    for (const tag of tags) {
      const element = new StructureElement({
        idTree: this.childId++,
        id: this.key(this.props.docId, tag.target, tag.type, undefined),
        docId: this.props.docId,
        target: String(tag.target),
        type: String(tag.type),
        name: String(tag.label),
        idCategory: String(this.props.idCategory),
        since: String(tag.data_decorrenza),
        historySize: Number(tag.historySize),
        version: '0',
        breadcrumb: parentName + "/" + tag.label,
      });
      this.idMap.set(element.id, element.idTree);

      // Se l'elemento ha più versioni, aggiungi N elementi, uno per ogni versione

      if (element.historySize > 0) {
        const historyElements: StructureElement[] = [];
        for (let i = 0; i < element.historySize; i++) {
          historyElements.push(
            new StructureElement({
              idTree: this.childId++,
              id: this.key(
                this.props.docId,
                tag.target,
                tag.type,
                String(i + 1)
              ),
              docId: this.props.docId,
              target: String(tag.target),
              type: String(tag.type),
              name: 'Versione ' + String(i + 1),
              version: String(i + 1),
              idCategory: String(this.props.idCategory),
              since: String(tag.data_decorrenza),
              historySize: Number(tag.historySize),
            })
          );
        }
        //console.log(historyElements);
        element.addChildren(historyElements);
      }

      children.push(element);
      if (tag.tags && element.historySize == 0) {
        element.addChildren(this.buildChildren(tag.tags, parentName + "/" + tag.label));
      }
    }
    return children;
  }

  get template(): string {
    return (
      '<v-card elevation="4"><v-card-text>' +
      this.props.text +
      '</v-card-text></v-card>'
    );
  }

  // GETTERs and SETTERs
  get idTree(): number | undefined {
    return this.props.idTree;
  }

  set idTree(idTree: number | undefined) {
    this.props.idTree = idTree;
  }

  get id(): string {
    return this.props.id;
  }

  set id(id: string) {
    this.props.id = id;
  }

  get name(): string {
    return this.props.name;
  }

  set name(name: string) {
    this.props.name = name;
  }

  get text(): string | undefined {
    return this.props.text;
  }

  set text(text: string | undefined) {
    if (text) {
      this.props.text = text;
    }
  }
}
