interface StructureElementProps {
  // Deve essere number per il TreeView
  idTree: number;
  // id, composto da "docId#target#type"
  id: string;
  docId: string;
  target: string;
  type: string;
  version?: string;
  name: string;
  idCategory: string;
  text?: string;
  historySize: number;
  since: string;
  to?: string;
  breadcrumb?: string;
}
export class StructureElement {
  private props: StructureElementProps;

  private children: StructureElement[] = [];

  constructor(props: StructureElementProps) {
    this.props = props;
  }

  addChild(element: StructureElement) {
    this.children.push(element);
  }

  addChildren(elements: StructureElement[]) {
    this.children = elements;
  }

  getChildren(): StructureElement[] {
    return this.children;
  }

  // GETTERs and SETTERs
  get idTree(): number {
    return this.props.idTree;
  }

  set idTree(idTree: number) {
    this.props.idTree = idTree;
  }

  get id(): string {
    return this.props.id;
  }

  set id(id: string) {
    this.props.id = id;
  }

  get docId(): string {
    return this.props.docId;
  }

  set docId(docId: string) {
    this.props.docId = docId;
  }

  get target(): string {
    return this.props.target;
  }

  set target(target: string) {
    this.props.target = target;
  }

  get type(): string {
    return this.props.type;
  }

  set type(type: string) {
    this.props.type = type;
  }

  get version(): string | undefined {
    return this.props.version;
  }

  set version(version: string | undefined) {
    this.props.version = version;
  }

  get name(): string {
    return this.props.name;
  }

  set name(name: string) {
    this.props.name = name;
  }

  get idCategory(): string {
    return this.props.idCategory;
  }

  set idCategory(idCategory: string) {
    this.props.idCategory = idCategory;
  }

  get since(): string {
    return this.props.since;
  }

  set since(since: string) {
    this.props.since = since;
  }

  get to(): string | undefined {
    return this.props.to;
  }

  set to(to: string | undefined) {
    this.props.to = to;
  }

  get historySize(): number {
    return this.props.historySize;
  }

  get text(): string | undefined {
    return this.props.text;
  }

  set text(text: string | undefined) {
    if (text) {
      this.props.text = text;
    }
  }

  get breadcrumb(): string | undefined {
    return this.props.breadcrumb;
  }

  set breadcrumb(breadcrumb: string | undefined) {
    this.props.breadcrumb = breadcrumb;
  }

}
